import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
    languages: [],
    cardsIds: [],
  },
  getters: {
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    setUserLanguages(state, languages) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.user.home_language_id = languages.homeLanguageId;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.user.target_language_id = languages.targetLanguageId;
    },
    setLanguages(state, languages) {
      state.languages = languages
    },
    setCardsIds(state, cardsIds) {
      state.cardsIds = cardsIds
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user)
    },
    clearUser({ commit }) {
      commit('setUser', null)
    }
  },
  modules: {
  }
})
