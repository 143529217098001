import axios from 'axios';
import router from './router';

// Create axios instance with bearer token in header

const token = localStorage.getItem('token');

const api = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_APP,
});

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log(error, 'error');
    const originalRequest = error.config;
    const url = originalRequest.url;

    // If status was unauthorized and this isn't a retry
    if (error.response.status === 401 && !originalRequest._retry && url !== '/login' && url !== '/join') {
      originalRequest._retry = true;
      
      // Try to get a new access token
      const refresh = localStorage.getItem('refresh');
      const res = await api.post('/refresh', { refresh }).catch((err) => {
        console.log(err, 'err');
      });
    
      console.log(res, 'res');

      if(res) {
        const accessToken =  res.data.newJwt.token;

        localStorage.setItem('refresh', res.data.newJwt.refreshToken);
        localStorage.setItem('token', accessToken);

        if (accessToken) {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return api(originalRequest);
        }
      }
    }

    return Promise.reject(error);
  }
);

// define routes

interface Card {
  word: string;
  translation: string;
  image: string;
  sentences: string;
}

export const translate = (word: string, isReverse: boolean) => api.post('/translation', { word, isReverse });
export const getImage = (sentence: string, word: string, save: boolean) => api.post('/create-image', { sentence, word, save });
export const regenerateImage = (prompt_image: string, save: boolean) => api.post('/regenerate-image', { prompt_image, save });
export const createCard = (card: Card) => api.post('/card', card);
export const getCards = () => api.get('/cards');
export const deleteCard = (id: string) => api.delete(`/card/${id}`);
export const getCardsForLearn = () => api.get('/cards/lowest-rating');
export const updateRatingOfCard = (id: number | string) => api.put(`/card/${id}/rating`);
export const updateCardById = (id: number | string, card: Card) => api.put(`/card/${id}`, card);
export const sendAnswerOnCard = (id: number | string, isCorrect: boolean, isReversed: string) => api.put(`/card/${id}/answer-card`, { isCorrect, isReversed });
export const getCardForLearn = (id: number | string) => api.get(`/learn`);
export const getCardById = (id: number | string) => api.get(`/card/${id}`);
export const getLanguages = () => api.get('/languages');
export const logout = () => api.post('/logout');
export const setLanguages = (homeLanguageId: number | string, targetLanguageId: number | string) => api.post('/user/languages', { homeLanguageId, targetLanguageId });
export const setTeacherRole = () => api.post('/user/teacher');
export const join = (email: string, password: string) => api.post('/join', { email, password });
export const loginViaEmailAndPassword = (email: string, password: string) => api.post('/login', { email, password });
export const joinToClass = (classId: number | string) => api.get(`/classes/join/${classId}`);
export const getStudentsInClass = (classId: number | string) => api.post(`/classes/${classId}/students`);
export const getClassesByUser = () => api.get('/classes/get-by-user');
export const getStudentWords = (studentId: number | string) => api.post(`/classes/student/${studentId}`);
export const updateStudentWord = (card: Card) => api.post(`/classes/update-word`, { card });
export const createWordForStudent = (card: Card) => api.post(`/classes/create-word-for-student`, { card });
export const deleteStudentWord = (studentId: number | string, wordId: number | string) => api.delete(`/classes/student/${studentId}/word/${wordId}`);
export const importCards = (cards: Card[]) => api.post('/import', { cards });
export const restorePassword = (email: string) => api.post('/restore', { email });
export const changePassword = (token: string, password: string) => api.post('/restore-password', { token, password });