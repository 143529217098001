import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '../store'
import HomeView from '../views/HomeView.vue'
import RegistrationView from '../views/RegistrationView.vue';
import LoginView from '../views/LoginView.vue';
import ListOfWordsView from '../views/ListOfWordsView.vue';
import AuthView from '../views/AuthView.vue';
import LearnView from '../views/LearnView.vue';
import LogoutView from '../views/LogoutView.vue';
import RestoreAccount from '../views/RestoreAccountView.vue';
import LanguageSelectionView from '../views/LanguageSelectionView.vue';
import ClassView from '../views/ClassView.vue';
import StudentView from '../views/StudentView.vue';
import ProfileSettingView from '../views/ProfileSettingsView.vue';
import JoinInClassView from '../views/JoinInClassView.vue';
import RestorePassword from '../views/RestorePasswordView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'translation',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileSettingView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/restore-account',
    name: 'restore-account',
    component: RestoreAccount
  },
  {
    path: '/list-of-words',
    name: 'list-of-words',
    component: ListOfWordsView
  },
  {
    path: '/restore-password/:token',
    name: 'restore-password',
    component: RestorePassword
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
  },
  {
    path: '/learn',
    name: 'learn',
    component: LearnView,
  },
  {
    path: '/join-in-class/:id',
    name: 'join-in-class',
    component: JoinInClassView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/language-selection',
    name: 'language-selection',
    component: LanguageSelectionView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/class',
    name: 'class',
    component: ClassView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/student/:id',
    name: 'student',
    component: StudentView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/card/:id',
    name: 'card',
    component: () => import(/* webpackChunkName: "card" */ '../views/CardView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth) && !store.state.user) {
    next('/auth')
    return;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (to.name !== 'language-selection' && to.name !== 'auth' && to.name !== 'login' && to.name !== 'registration' && to.name !== 'restore-account' && to.name !== 'restore-password' && !store?.state?.user?.home_language_id) {
    next('/language-selection')
    return;
  }

  next()
})


export default router
