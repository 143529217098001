<template>
  <ListOfWords
    :listOfItems="cards"
    @delete-item-by-id="deleteItemById"
    @edit-item-by-id="editItemById"
  ></ListOfWords>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { getCards, deleteCard } from "../api";
import ListOfWords from "../components/ListOfWords.vue";
import { useStore } from "vuex";


const cards = ref([]);
const router = useRouter();
const store = useStore();

onMounted(async () => {
  const { data } = await getCards();
  cards.value = data.cards;
  store.commit("setCardsIds", data.ids);
});

const deleteItemById = async (id) => {
  try {
    await deleteCard(id);
    cards.value = cards.value.filter((card) => card.id !== id);

    window.dispatchEvent(
      new CustomEvent("add-card", {
        detail: {
          message: "Card was deleted",
          type: "success",
        },
      })
    );

  } catch (error) {
    console.log(error);

    window.dispatchEvent(
      new CustomEvent("add-card", {
        detail: {
          message: "Card was not deleted",
          type: "error",
        },
      })
    );
  }

  cards.value = cards.value.filter((card) => card._id !== id);
};

const editItemById = (id) => {
  router.push(`/card/${id}`);
};
</script>
