<template>
  <div class="dark:bg-gray-900 min-h-screen">
    <VMenu />
    <div class="flex justify-center w-screen mt-2 px-4">
      <router-view />
      <transition name="slide">
        <VNotification v-if="isNotificationVisible" class="fixed bottom-9" :message="notificationMessage"
          :message-type="notificationType" @close="closeNotification" />
      </transition>
    </div>
  </div>
</template>

<script setup>

import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { parseTokenInformation } from "./helpers";
import VNotification from "./components/VNotification.vue";
import VMenu from "./components/VMenu.vue";
import { getLanguages } from './api';

const NOTIFICATION_VISIBLE_TIME = 3000;

const store = useStore();

const isNotificationVisible = ref(false);
const notificationMessage = ref("");
const notificationType = ref("");

const user = computed(() => store.state.user);

const closeNotification = () => {
  isNotificationVisible.value = false;
};

onMounted(async () => {
  initUser();
  initLanguages();

  window.addEventListener("add-card", (event) => {
    isNotificationVisible.value = true;
    notificationMessage.value = event.detail.message;
    notificationType.value = event.detail.type;

    setTimeout(() => {
      isNotificationVisible.value = false;
    }, NOTIFICATION_VISIBLE_TIME);
  });
});

const initUser = () => {

  const token = localStorage.getItem("token");
  if (!token) {
    return;
  }

  const tokenInformation = parseTokenInformation(token);
  const payload = tokenInformation.data;
  console.log(payload, 'payload');
  store.dispatch("setUser", payload);
}

const initLanguages = async () => {
  try {
    const { data } = await getLanguages().catch((error) => {
      console.log(error);
    });
    store.commit("setLanguages", data);
  } catch (error) {
    console.log(error);
  }
}
</script>

<style lang="scss">
@import "./assets/tailwind.css";

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.slide-enter-to,
.slide-leave {
  transform: translateY(0);
  opacity: 1;
}
</style>
