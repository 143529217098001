<template>
  <div class="class w-full">
    <h3 class="text-2xl font-bold dark:text-white">Valery Khizhevsky</h3>
    <div class="my-5 flex">
      <div class="flex-1 mr-2">
        <label class="block text-sm font-medium text-gray-900 dark:text-white">Text</label>
        <input v-model="word"
          class="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
      <div class="flex-1">
        <label class="block text-sm font-medium text-gray-900 dark:text-white">Translation</label>
        <input v-model="translation"
          class="bg-gray-50 mt-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
      </div>
    </div>
    <div class="flex w-full">
      <button @click="addCardToStudent" type="button"
        class="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800 justify-self-end">
        Add to cards
      </button>
    </div>
    <div class="mt-3 relative overflow-x-auto shadow-md sm:rounded-lg">
      <!-- <div class="flex items-center justify-between py-4 bg-white dark:bg-gray-800">
        <label for="table-search" class="sr-only">Search</label>
        <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center ml-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
          <input type="text" id="table-search-users"
            class="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for users">
        </div>
      </div> -->
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="p-4">
            </th>
            <th scope="col" class="px-6 py-3">
              Text
            </th>
            <th scope="col" class="px-6 py-3">
              Translation
            </th>
            <th scope="col" class="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            v-for="(word, index) in words" :key="`word-id-${index}`">
            <th scope="row" class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
              <img class="w-12 h-12" :src="`${BACKEND_APP}/public/${word.image}`" alt="Jese image">
            </th>
            <td class="px-6 py-4">
              <input v-model="word.word" @input="updateWord(word)"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" />
            </td>
            <td class="px-6 py-4">
              <input v-model="word.translation" @input="updateWord(word)"
                class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" />
            </td>
            <td class="px-6 py-4">
              <!-- Modal toggle -->
              <a @click.prevent="deleteCard(word.id)" class="font-medium text-red-600 dark:text-red-500 hover:underline">Delete</a>
            </td>
          </tr>

        </tbody>
      </table>
    </div>

  </div>
</template>

<script setup>
import VButton from "../components/VButton.vue";
import { onMounted, ref } from "vue";
import { getStudentWords, updateStudentWord, createWordForStudent, deleteStudentWord } from "../api";
import { useRouter } from "vue-router";
import { useDebounceFn } from '@vueuse/core';

const BACKEND_APP = process.env.VUE_APP_BACKEND_APP;
const DEBOUNCE_TIME = 300;

const router = useRouter();
const words = ref([]);

const word = ref('');
const translation = ref('');
const studentId = ref('');

onMounted(async () => {
  studentId.value = router.currentRoute.value.params.id;
  try {
    const { data } = await getStudentWords(studentId.value);
    words.value = data;
  } catch (e) {
    console.log(e);
  }
});

const sendRequest = useDebounceFn(async (value) => {
  try {
    const { data } = await updateStudentWord(value);
  } catch (e) {
    console.log(e);
  }
}, DEBOUNCE_TIME);

const updateWord = (word) => {
  sendRequest(word);
}

const addCardToStudent = async () => {
  if(!word.value || !translation.value) {
    return;
  }

  try {
    const { data } = await createWordForStudent({
      word: word.value,
      translation: translation.value,
      studentId: studentId.value,
    });
    words.value.shift(data);
    word.value = '';
    translation.value = '';
  } catch (e) {
    console.log(e);
  }
}

const deleteCard = async (wordId) => {
  try {
    await deleteStudentWord(studentId.value, wordId);
    words.value = words.value.filter(word => word.id !== wordId);
  } catch (e) {
    console.log(e);
  }
}
</script>