<template>
  <div class="flex flex-col items-center" v-if="!isEmpty && card && !ifLessonCompleted">
    <div class="wrapper">
      <span v-show="!ifTimeExpired"
        class="bg-gray-100 text-gray-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300"
        >{{ formatTimeSecondsAndMinutes }}</span
      >
    </div>

    <VCard
      class="mt-2"
      @yes="handleYes"
      @no="handleNo"
      :word="wordForPreview"
      :translation="card.translation"
      :sentences="card.sentences"
      :image-url="`${BACKEND_APP}/public/${card.image}`"
      :is-flipped="card.isFlipped"
      :audio="card.audio"
      @flip="flipCard"
    />
  </div>
  <div v-else-if="ifLessonCompleted" class="mt-5 text-white">
    Lesson completed, good job!
  </div>
  <div v-else class="mt-5 text-white">No cards to learn</div>
</template>

<script setup>
import {
  getCardsForLearn,
  updateRatingOfCard,
  sendAnswerOnCard,
  getCardForLearn,
} from "../api";
import { ref, onMounted, computed } from "vue";
import VCard from "../components/VCard.vue";

const BACKEND_APP = process.env.VUE_APP_BACKEND_APP;

const card = ref(null);
const audio = ref({});
const isEmpty = ref(false);
const ifLessonCompleted = ref(false);

const LESSON_IN_MINUTES = 10;
const startOfLesson = ref(new Date().getTime());
const endOfLesson = new Date(
  new Date().getTime() + LESSON_IN_MINUTES * 60000
).getTime();

setInterval(() => {
  startOfLesson.value += 1000;

  if (startOfLesson.value >= endOfLesson) {
    clearInterval();
  }
}, 1000);

onMounted(async () => {
  await getNewCard();
});

const handleYes = async () => {
  try {
    await sendAnswerOnCard(card.value.id, true, card.value.isReversed);
    await getNewCard();
  } catch(error) {
    console.log(error);
  } finally {
    if (ifTimeExpired.value) {
      ifLessonCompleted.value = true;
    }
  }
};

const handleNo = async () => {
  try {
    await sendAnswerOnCard(card.value.id, false, card.value.isReversed);
    await getNewCard();
  } catch (error) {
    console.log(error);
  } finally {
    if (ifTimeExpired.value) {
      ifLessonCompleted.value = true;
    }
  }
};

const flipCard = () => {
  try {
    if(audio.value) {
      audio.value.play();
    }
  } catch (error) {
    console.log(error);
  }
  card.value.isFlipped = true;
};

const getNewCard = async () => {
  try {
    const { data } = await getCardForLearn();

    if (!data) {
      isEmpty.value = true;
      return;
    }

    data.isFlipped = false;
    card.value = data;
    audio.value = new Audio(
      `${process.env.VUE_APP_BACKEND_APP}/public/audio/${card.value.audio}`
    );
  } catch (error) {
    console.log(error);
  }
};

const formatTimeSecondsAndMinutes = computed(() => {
  const time = new Date(endOfLesson - startOfLesson.value);
  const minutes =
    time.getMinutes() < 10 ? `0${time.getMinutes()}` : time.getMinutes();
  const seconds =
    time.getSeconds() < 10 ? `0${time.getSeconds()}` : time.getSeconds();

  return `${minutes}:${seconds}`;
});

const ifTimeExpired = computed(() => {
  return startOfLesson.value >= endOfLesson;
});

const wordForPreview = computed(() => {
  if (card.value.isReversed && !card.value.isFlipped) {
    return card.value.translation;
  }

  return card.value.word;
});
</script>
