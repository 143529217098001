<template>
  <div class="flex flex-col w-full">
    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
      Your role is: {{ user.role }}
    </p>
    <button v-if="user.role !== 'teacher'" @click="onSetTeacherRole" type="submit"
      class="mt-10 text-white w-full bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
      Set teacher role
    </button>
  </div>
</template>

<script setup>
import { setTeacherRole } from "../api.ts";
import VButton from "../components/VButton.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed } from "vue";

const store = useStore();
const router = useRouter();

const user = computed(() => store.state.user);

const onSetTeacherRole = async () => {
  try {
    await setTeacherRole();
    router.push({ name: "class" });
  } catch (e) {
    console.log(e);
  }
}
</script>