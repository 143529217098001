<template>
  <div class="flex flex-col w-full">
    <h1 class="text-white">{{ title }}</h1>
    <VButton
      class="mt-1"
      v-for="language in filteredLanguages"
      :key="language.id"
      @click="selectLanguage(language)"
      >{{ language.emoji }} {{ language.name }}</VButton
    >
    <button
      v-show="selectedHomeLanguage && selectedTargetLanguage"
      @click="onSetLanguages"
      type="submit"
      class="mt-4 text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    >
      Save languages
    </button>
  </div>
</template>

<script setup>
import { getLanguages, setLanguages, setTeacherRole } from "../api.ts";
import { onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import VButton from "../components/VButton.vue";

const router = useRouter();
const store = useStore();
const languages = ref([]);
const selectedHomeLanguage = ref(null);
const selectedTargetLanguage = ref(null);


onMounted(async () => {
  const { data } = await getLanguages();
  languages.value = data;
});

const title = computed(() => {
  if (selectedHomeLanguage.value && !selectedTargetLanguage.value) {
    return "Select your target language";
  } else if (selectedTargetLanguage.value) {
    return `You selected ${selectedTargetLanguage.value.name} as your target language and ${selectedHomeLanguage.value.name} as your home language`;
  }
  return "Select your homeland language";
});

const selectLanguage = (language) => {
  if (selectedHomeLanguage.value) {
    selectedTargetLanguage.value = language;
  } else {
    selectedHomeLanguage.value = language;
  }
  console.log(selectedHomeLanguage.value, selectedTargetLanguage.value);
};

const filteredLanguages = computed(() => {
  if (selectedTargetLanguage.value && selectedHomeLanguage.value) {
    return [];
  }

  if (selectedHomeLanguage.value) {
    return languages.value.filter(
      (language) => language.id !== selectedHomeLanguage.value.id
    );
  }
  return languages.value;
});

const onSetLanguages = async () => {
  try {
    const { data } = await setLanguages(
      selectedHomeLanguage.value.id,
      selectedTargetLanguage.value.id
    );
    store.commit("setUserLanguages", {
      homeLanguageId: selectedHomeLanguage.value.id,
      targetLanguageId: selectedTargetLanguage.value.id,
    });
    
    localStorage.setItem("token", data.token);
    localStorage.setItem("refresh", data.refreshToken);
    
    router.push({ name: "translation" });
  } catch (error) {
    console.log(error);
  }
};

const onSetTeacherRole = async () => {
  try {
    await setTeacherRole();
    router.push({ name: "class" });
  } catch (e) {
    console.log(e);
  }
}
</script>
