<template>
  <div class="class w-full">
    <h3 class="text-2xl font-bold dark:text-white">Invite link to your class</h3>

    <h6 class="flex mt-3 items-center font-extrabold dark:text-white"><span
        class="bg-blue-100 text-blue-800 font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-2">{{ HOST_DOMEN }}/classes/{{ classId }}</span>
    </h6>

    <h3 class="text-2xl mt-3 font-bold dark:text-white">Students</h3>

    <ul class="mt-3 w-full divide-y divide-gray-200 dark:divide-gray-700">
      <li class="py-1 sm:pb-4">
        <div class="flex items-center space-x-4" v-for="(student, index) in students" :key="`student-${index}`">
          <!-- <div class="flex-shrink-0">
            <img class="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-1.jpg" alt="Neil image">
          </div> -->
          <div class="flex-1 min-w-0">
            <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
              {{ student.name }}
            </p>
            <p class="text-sm text-gray-500 truncate dark:text-gray-400">
              {{ student.email }}
            </p>
          </div>
          <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
            <VButton @click="handleStudent(student.id)">view</VButton>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import VButton from "../components/VButton.vue";
import { onMounted, ref } from "vue";
import { getStudentsInClass, getClassesByUser } from "../api";
import { useRouter } from "vue-router";

const students = ref([]);
const router = useRouter();
const classId = ref('');
const HOST_DOMEN = process.env.VUE_APP_HOST_DOMEN

onMounted(async () => {
  try {
    const { data } = await getClassesByUser();
    classId.value = data;
    const resStudents = await getStudentsInClass(data);
    students.value = resStudents.data;
  } catch (e) {
    console.log(e);
  }
});

const handleStudent = (id) => {
  router.push(`/student/${id}`);
}
</script>