<template>
  <nav
    class="bg-white dark:bg-gray-900 w-full fixed z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600"
  >
    <div
      class="flex flex-wrap items-center justify-between mx-auto p-4"
    >
      <a href="/" class="flex items-center">
        <span
          class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"
          >🐘 Memmri</span
        >
      </a>
      <div class="flex">
        <button
          v-if="isUserLoggedIn"
          @click="isMenuOpen = !isMenuOpen"
          data-collapse-toggle="navbar-sticky"
          type="button"
          class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-sticky"
          aria-expanded="false"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <div
        v-if="isUserLoggedIn && isMenuOpen"
        v-on-click-outside="closeMenu"
        class="items-center justify-between w-full"
        id="navbar-sticky"
      >
        <ul
          v-if="activeRoute"
          @click="isMenuOpen = false"
          class="flex flex-col p-4 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700"
        >
          <li>
            <router-link
              v-for="route in routes"
              :key="route.name"
              :to="route.to"
              :class="{
                'bg-blue-700 text-white': activeRoute.name === route.name
              }"
              class="block py-2 pl-3 pr-4 dark:text-white hover:bg-gray-400 rounded"
              >{{ route.title }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div class="duck-tape" style="height: 72px"></div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { vOnClickOutside } from '@vueuse/components'
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();

const isMenuOpen = ref(false);


const routes = [
  {
    to: '/',
    name: 'translation',
    title: 'Translation',
  },
  {
    to: '/list-of-words',
    name: 'list-of-words',
    title: 'List of words'
  },
  {
    to: '/learn',
    name: 'learn',
    title: 'Learn'
  },
  // {
  //   to: '/class',
  //   name: 'my class',
  //   title: 'My class'
  // },
  {
    to: '/language-selection',
    name: 'language-selection',
    title: 'Change languages'
  },
  {
    to: '/profile',
    name: 'profile',
    title: 'Profile'
  },
  {
    to: '/logout',
    name: 'Logout',
    title: 'Logout'
  }
]

const activeRoute = computed(() => {
  const route = routes.find((route) => route.name === router.currentRoute.value.name);

  return route || true;
});

const logout = () => {
  console.log("logout");
  localStorage.removeItem("token");
  localStorage.removeItem("refresh");
  router.push({ name: "registration" });
};

const isUserLoggedIn = computed(() => {
  return store.state.user !== null;
});

const closeMenu = () => {
  // isMenuOpen.value = false;
  // next tick to avoid conflict with v-on-click-outside
  setTimeout(() => {
    isMenuOpen.value = false;
  }, 0);
};
</script>
