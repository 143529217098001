<template>
  <ul class="w-full divide-y divide-gray-200 dark:divide-gray-700">
    <li @click="$emit('edit-item-by-id', item.id)" class="pb-1 pt-1 duration-200 active:bg-gray-700"
      v-for="(item, index) in listOfItems" :key="`item-${index}`">
      <div class="flex items-center space-x-4">
        <div>
          <img v-if="item.image" class="w-10 h-10 ml-2" :src="`${BACKEND_APP}/public/${item.image}`" alt="Image for word" />
          <svg v-else class="w-10 h-10 text-gray-200 dark:text-gray-600 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"/>
          </svg>
        </div>
        <div class="flex-1 min-w-0">
          <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
            {{ item.word }} - {{ item.translation }}
          </p>
          <p class="text-sm text-gray-500 truncate dark:text-gray-400">
            {{ item.sentences }}
          </p>
        </div>
      </div>
    </li>
    <p class="text-sm font-medium text-gray-900 truncate dark:text-white" v-if="!listOfItems.length">
      No words here
    </p>
  </ul>
</template>

<script setup>
import { ref, defineProps } from "vue";

const BACKEND_APP = process.env.VUE_APP_BACKEND_APP;

defineProps({
  listOfItems: {
    type: Array,
    required: true,
  },
});
</script>
