<template>
  <p>Logout</p>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { logout } from "../api";

const store = useStore();
const router = useRouter();

const resetStore = async () => {
  try {
    await logout();
  } catch (error) {
    console.log(error);
  } finally {
    store.dispatch("setUser", null);
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
    router.push({ name: "login" });
  }
};

resetStore();
</script>
