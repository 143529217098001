<template>
  <div class="class w-full">

    <p class="max-w-lg text-xl font-semibold leading-relaxed text-gray-900 dark:text-white">Do you want to join in class?</p>
    <div class="flex">
      <button type="button" class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700" @click="goHome">No</button>
      <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" @click="joinInClass">Yes</button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { joinToClass } from "../api";

const router = useRouter();
const store = useStore();
const classId = router.currentRoute.value.params.id;

const goHome = () => {
  router.push({ name: "translation" });
}

const joinInClass = async () => {
  try {
    const data = await joinToClass(classId);
    router.push({ name: "translation" });
  } catch (e) {
    console.log(e);
  }
}
</script>